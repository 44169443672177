<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">ご利用ガイド</div>

        <div class="sentent_r1">
          サンタクロース大使館における、ご注文の手順をご案内いたします。
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step1.jpg" alt="" />
          </div>

          <div class="grgt">
            <h2>【ステップ１：基本内容の選択】</h2>

            ①お手紙のお申込み枚数を選択してください。一度に最大１０通までご注文が可能です。<br />
            <br />
            ②文面先頭に表示されるお受取人様の呼びかけ名をご入力ください。<br />
            <br />
            ③お手紙の対象をご選択ください。幼児・お子様・一般の方向けと３タイプをご用意致しております。<br />
            <br />
            ④お手紙の言語をご選択ください。③でお選びいただきましたお受取人様に合わせた<br />
            文面を日本語・英語からお選びいただけます。ご入力が完了いたしましたら次へお進みください。<br />
            <br />
            <br />
            ⑤２通以上お申込みのお客様は、①〜④を再度繰り返し入力・ご選択いただき、次へお進みください。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step2a.jpg" alt="" /><img
              src="../../assets/images/guide/step2b.jpg"
              alt=""
            />
          </div>
          <div class="grgt">
            <h2>【ステップ２：お手紙詳細内容の選択】</h2>
            ①メッセージの入力：お手紙の文末に６０文字以内にて、お受取人様へのオリジナルメッセージをご入力いただくことが可能です。お受取人様へ届けたいメッセージをサンタさんの言葉を借りてお伝えしたり、ご注文者様からのメッセージを添えたりと、ご利用用途は様々です。<br />
            ※ご入力がなくても、この他に本文がございますのでお手紙にはサンタさんからのメッセージがしっかり記載された状態となります。本文は毎年到着するまでのお楽しみです☆<br />
            <br />
            ③オプションを追加する：サンタクロースからのお手紙だけではちょっと寂しい！一緒になにかプレゼントを添えてあげたい！というお客様からの声にお応えして、サンタクロース大使館では数量限定にて厳選されたオリジナルプレゼントをご用意致しております。<br />
            一番人気の「良い子のしるしアクリルキーホルダー」を始め、毎年多くのお客様にお喜びを頂いております。<br />
            <br />
            送料無料範囲商品：追加送料なし、クロネコゆうパケット配送料金の３２０円のみで同梱が可能な商品を指します。ご注文が一定数量を超えてしまいますと商品サイズの兼ね合い上、クロネコゆうパケットでの配送ができなくなり、ヤマト運輸、宅急便料金（別途地域別送料）が加算されますのでご注意くださいませ。詳細はお申し込みページ内にも詳しく表示致しておりますのでご参照しながら、お受取人様にお喜びいただけるアイテムをチョイスしてくださいね☆<br />
            <br />
            宅急便対応商品：商品のサイズがクロネコゆうパケットでは発送対応外の商品です。毎年デザインが変わる「クッキー入りポーチ」を始め、お子様から大人の方までお喜び頂ける商品を取り揃えております。お手紙料金に加えてお届け先地域別の宅急便料金が別途かかりますが、代わりに１２月１７日〜２５日※までの日時指定便でのお届けが可能というメリットもございます。<br />
            <br />
            ※お届け日時のご指定は、11月30日ご注文完了分までの受付となります。なお、お支払い方法でコンビニ決済・銀行振込・郵便振替をご選択の場合、12月10日までにご入金が完了できたお客様のみ適用可能となります。<br />
            <br />
            <br />
            ④オプションの選択まで完了したら「次へ進む」を選択します。
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step3.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ステップ３：お手紙プレビュー】</h2>
            ステップ１でご入力いただきました「呼びかけ名」（先頭）とメッセージ（文末）がお申込みいただいた通数分表示されます。誤字脱字がないか、使用できない文字が無いか※など、必ずご確認ください。<br />
            <br />
            <br />
            問題がなければ次に進みます。<br />
            修正がある場合は前へもどるボタンをクリックして<br />
            修正必要箇所までお戻りいただき、再度正しく入力を行います。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step4.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ステップ４：ご依頼主様情報の入力】</h2>

            ①会員ログイン：過去にご注文を頂いておりますお客様で会員登録をしているお客様はこちらで、必ずログインしてからご注文へお進みください。<br />
            ご注文完了後にマイページから注文状況の確認や編集が行えます。<br />
            ログインいただきますと、ご注文者様名やご注文者様住所等が自動的にお申込画面に反映されます。※<br />
            <br />
            ※もしお引越しをされた等でお住まいの住所やお電話番号が変わった際は、<br />
            「情報を変更する」ボタンをクリックして、ご注文者様情報を編集してください。<br />
            最後に「変更内容を保存」をクリックすることで、新しい情報が登録され<br />
            ステップ４のご依頼主様情報も更新されます。<br />
            <br />
            <br />
            ご注文者様情報をよく確認していただき、お間違いがないようでしたら<br />
            「次へ進む」をクリックしてください。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step5.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ステップ５：お届先情報の入力】</h2>

            ①お届け先名を漢字とふりがなにてご入力ください。<br />
            <br />
            ②お届先情報をご入力ください<br />
            <br />
            ※ご注文者様と同じ住所の場合は「ご依頼主様の住所を読み込む」ボタンを<br />
            クリックすると同じご住所が反映されます。<br />
            <br />
            ※ご住所（番地、マンション名、部屋番号等）、お電話番号等をよくご確認ください。<br />
            特に郵便番号誤りによる住所の不備が大変多くなっておりますのでご注意ください。<br />
            <br />
            ※お受取人様のお名前が異なる場合は◯◯様方など、「表札」が出ているお名前をご指定ください。<br />
            <br />
            ③配送方法をご選択ください<br />
            <br />
            ※クロネコゆうパケットか宅急便をお選びいただけますが、ステップ２で宅急便対応条件にて<br />
            商品を追加購入いただいているお客様は、配送方法が宅急便しかお選びいただけなくなっております。<br />
            <br />
            <br />
            ④宅急便をご利用のお客様で配達日時指定の有無をご選択ください。<br />
            クリスマス商品という性質の兼ね合い上、お届期間は１２月１７日〜２５日のみ<br />
            ご指定が可能とさせていただきます。<br />
            また日時をご指定いただくためには、指定期日までのお申込みとご入金確認が<br />
            必要となりますのでご注意くださいませ。
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step6.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ステップ６：お支払い方法の選択】</h2>

            ①お支払い方法の中から、ご希望の決済手段をお選びください。<br />
            クレジットカード・コンビニ決済・銀行振込・郵便振替などがご利用可能です。<br />
            <br />
            ※クレジットカード情報をご入力の場合でも、まだこの画面では注文は完了致しておりません！「最終確認画面へ」をクリックししばらく次の画面が表示されるまでそのままお待ち下さい。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step7.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ステップ７：ご注文の最終確認】</h2>

            ①ご注文者様情報をご確認ください。<br />
            誤りがあれば編集ボタンをクリックして編集可能です。<br />
            <br />
            <br />
            ②お申込内容を確認してください。左側に表示されているプレビューを画像をクリックすると拡大表示されます。呼びかけ名・メッセージ文章内容・宛名シートの内容などを最終確認願います。変更が生じた際にはこちらも編集ボタンから該当箇所の修正が可能です。<br />
            <br />
            ③決済情報と決済金額をご確認ください。<br />
            <br />
            ④お申込みの実行ボタンを「１回だけ」ゆっくりクリックしてください。<br />
            <br />
            ⑤そのまましばらくお待ち下さい。クレジットカードの場合にはこの段階で決済手続きを行いますため、処理に若干のお時間がかかります。次の画面が表示されるまでそのままお待ち下さい。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft">
            <img src="../../assets/images/guide/step8.jpg" alt="" />
          </div>
          <div class="grgt">
            <h2>【ご注文完了画面】</h2>
            パターンA:お支払いをお待ちしていますと表示のお客様<br />
            →ご登録のメールアドレスに注文完了メールやコンビニ決済の場合は「決済代行会社イプシロン」よりご入金お手続きに関するご案内メールが届きます。<br />
            内容をご確認の上、なるべく早くお支払い手続きを完了ください。<br />
            <br />
            パターンB:代金のお支払いが完了しましたと表示のお客様<br />
            →クレジットカード決済のお客様のみ、正常にお支払い手続きが完了いたしますとこちらのメッセージが表示されます。併せましてご注文確認メールとご入金確認メールをご確認くださいませ。<br />
          </div>
        </div>

        <div class="guide">
          <div class="gleft"></div>
          <div class="grgt">
            <h2>【ご入金確認】</h2>

            お手紙のお申し込みは、当社がお客様からの入金を確認し、「ご入金確認メール」をお送りした時点で「お申し込み確定」となります。<br />
            ● クレジットカード支払：即日確認となります。<br />
            ●
            コンビニ支払：コンビニでのお支払後約１時間程度経過後の確認となります。<br />
            ●銀行振込：楽天銀行へのお振込みとなります。１〜３営業日程度でのご入金確認となります。<br />
            ●
            ゆうちょ銀行振込（郵便振替）：お振込３～４営業日程度での確認となります（土日祭日を除く）。
          </div>
        </div>

        <div class="guide">
          <div class="gleft"></div>
          <div class="grgt">
            <h2>【お手紙の発送について】</h2>

            ヤマト運輸と日本郵政の「クロネコゆうパケット」またはヤマト運輸「宅急便」にて発送いたします。
            ご入金が遅れますと、発送も遅れてしまいますので、ご注意下さい。
            お手紙は１２月中旬頃より順次発送いたします。
          </div>
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
.guide {
  border-bottom: solid 2px rgb(133, 133, 133);
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  .gleft {
    width: 200px;
    img {
      max-width: 100%;
    }
  }
  .grgt {
    flex: 1;
    padding: 0.5em 1.5em;
    font-size: 0.8em;
    text-align: left;
    h2 {
      margin-bottom: 0.8em;
    }
  }
}
</style>